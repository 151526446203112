import { AutomatitCarousel } from './automatit_carousel.m';

const reviewsCaro = {
	caro: document.querySelector('#reviews_caro'),
	init: function() {
		window.reviewsCaro = AutomatitCarousel({
			element: this.caro,
			useChevrons: false,
			intervalTiming: false
		});
	}
};

export const initReviewsCaro = () => reviewsCaro.init();