/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

let submitInProgress = false;

const updateResponseMessage = (errors) => {
	var response = '';

	if (errors.includes('fname')) {
		errors[errors.indexOf('fname')] = 'first name';
	}

	if (errors.includes('lname')) {
		errors[errors.indexOf('lname')] = 'last name';
	}

	if (errors.length === 1) {
		response = "The " + errors[0] + " field is required.";
	} else if (errors.length === 2) {
		response = "The " + errors[0] + " and " + errors[1] + " fields are required.";
	} else {
		let fields = "";
		for (let x = 0; x < errors.length; x++) {
			fields += errors[x];
			if (x < errors.length - 1) {
				fields += ', ';
			}
			if (x === errors.length - 2) {
				fields += 'and ';
			}
		}
		response = "The " + fields + " fields are required.";
	}

	document.querySelector('.response_message').innerHTML = "<span class='error'>" + response + "</span>";
};

/**
 * 
 * @param {Object} param0 Object of named parameters
 * 
 * elementSelector : the css selector for the form element
 * required : (String[]) 
 */

function gtag_report_conversion(url) {
	var callback = function () {
		if (typeof(url) !== 'undefined') {
			window.location = url;
		}
	};
	gtag('event', 'conversion', {
		'send_to': 'AW-782112873/QzGPCOvu6YsBEOmw-PQC',
		'value': 100.0,
		'currency': 'USD',
		'event_callback': callback
	});
	return false;
}

const _listenToForm = ({
	elementSelector = '#contact_form',
	required = ['fname', 'lname', 'email', 'message']
} = {}) => {

	document.querySelector(elementSelector).addEventListener('submit', function(e) {
		if (document.getElementById('hp').value !== '') return;
		const formEl = document.querySelector(elementSelector);
		Array.prototype.forEach.call(formEl.querySelectorAll('.error'), function(error) {
			error.classList.remove('error');
		});
		formEl.querySelector('.response_message').innerHTML = '';

		if (submitInProgress) {
			return;
		}

		// var checkFields = new Array('name', 'email', 'message');
		let errorsFound = new Array();
		required.forEach(function(item) {
			if (document.getElementById(item + '_input').value === '') {
				document.getElementById(item + '_input').classList.add('error');
				errorsFound.push(item);
			}
		});
		if (!submitInProgress && errorsFound.length === 0) {
			submitInProgress = true;

			let request = new XMLHttpRequest(),
				method = 'POST',
				url = formEl.getAttribute('action'),
				data = new FormData(formEl);

			request.onreadystatechange = function() {
				if (request.readyState === 4 && request.status === 200) {
					let response = JSON.parse(request.responseText);
					if (response.ret_det.success) {
						gtag_report_conversion();
						let input = document.getElementById('contact_form_submit');
						input.parentElement.removeChild(input);
						formEl.querySelector('.response_message').innerHTML = "<span class='success'>" + response.ret_det.message + "</span>";
					} else {
						if (typeof response.data.error_fields !== 'undefined') {
							let errors = [];
							for (let x = 0; x < response.data.error_fields.length; x++) {
								errors.push(response.data.error_fields[x].field_name);
							}
							updateResponseMessage(errors);
						} else {
							formEl.querySelector('.response_message').innerHTML = "<span class='error'>" + response.ret_det.message + "</span>";
						}
					}
					submitInProgress = false;
				}
			};
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		}
		else if (errorsFound.length > 0) {
			updateResponseMessage(errorsFound);
		}
		else {
			formEl.querySelector('.response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
		e.stopImmediatePropagation();
		e.preventDefault();
	});


};

export const contactSubmit = _listenToForm;

export const contactMaps = (function() {
	let my = {},
		multiMap = document.getElementById('contact_map'),
		mapData = document.querySelectorAll('#contact_map'),
		initialized = false;

	function _startMultiMap() {
		const mapHelper = getMapsHelper();

		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: multiMap,
					locationElementSelector: '#contact_map',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
                        <a class="map_pin" href="https://www.google.com/maps/place/Automatit,+Inc./@32.2927945,-110.9782856,19z/data=!4m5!3m4!1s0x86d673739e8578c3:0x51033238b468ceb2!8m2!3d32.2927458!4d-110.9776841" target="${el.getAttribute('target')}">
							<img src="/dist/images/icons/SVG/blue_mappin_ic.svg" alt="map_pin" height="45"  width="30"/>
                            <div><span></span></div>
                        </a>
                        `
						};
					}
				});

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if (mapData.length < 2) {
						theMap.setZoom(15);
					}
					[].forEach.call(mapData, i => {
						const markerData = mapHelper.getMarker(i);
						markerData.element.addEventListener('mouseenter', () => {
							i.classList.add('hover');
						});
						markerData.element.addEventListener('mouseleave', () => {
							i.classList.remove('hover');
						});
					});
				});
			});
	}

	function _mapInView() {
		const rect = multiMap.getBoundingClientRect();
		const isInView = (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 1000));
		return isInView;
	}

	function _assignListener() {
		if (_mapInView()) {
			_startMultiMap();
			initialized = true;
		} else {
			window.onscroll = function() {
				const rect = multiMap.getBoundingClientRect();
				const isInView = (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 1000));
				if (!initialized && isInView) {
					_startMultiMap();
					initialized = true;
				}
			};
		}
	}

	my.init = function() {
		if (multiMap) {
			_assignListener();
		}
	};

	return my;
})();
