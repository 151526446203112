export const printMobile = (function() {
	let my = {};

	my.init = function() {
		if (document.getElementById('print_graph')) {
			const cardButtons = document.querySelectorAll(".dot");
			const articles = document.querySelectorAll(".middle_cards article");
			const articles1 = document.querySelectorAll(".last_cards article");
			const titlecards = document.querySelectorAll('#google_basics article');

			cardButtons.forEach(function (button) {
				button.addEventListener("click", function () {
					const index = parseInt(button.dataset.index);
					// Hide all articles
					articles.forEach(function (article) {
						article.style.display = "none";
					});

					articles1.forEach(function (article) {
						article.style.display = "none";
					});

					titlecards.forEach(function(title) {
						title.style.display = "none";
					});

					titlecards[index].style.display = "block";
					articles[index].style.display = "block";
					articles1[index].style.display = "block";
				});
			});
		}
	};

	return my;
})();
