import { contactMaps, contactSubmit } from './contact.js';
import { navBar } from './header.js';
import { homeCaro } from './home.js';
import { miniCaro } from './mini_caro.js';
import { printMobile } from './print.js';
import { initReviewsCaro } from './reviews.js';
import { supportSubmit } from './support';
import { webToggle } from './website.js';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('support_form')) {
	supportSubmit();
}

if (document.querySelector('#reviews_caro')) {
	initReviewsCaro();
}

printMobile.init();
navBar.init();
contactMaps.init();
homeCaro.init();
webToggle.init();
miniCaro.init();

/* eslint-disable */
//Popup
const vacateButton = document.getElementById('flex_feats');
if (vacateButton) {
	vacateButton.addEventListener('click', () => {
		document.getElementById('container').classList.add('popup_vacate');
	});
}

const adsButton = document.getElementById('ads_feats');
if (adsButton) {
	adsButton.addEventListener('click', () => {
		document.getElementById('container').classList.add('popup_ads');
	});
}

const familyButton = document.getElementById('family_feats');
if (familyButton) {
	familyButton.addEventListener('click', () => {
		document.getElementById('container').classList.add('popup_family');
	});
}

const aspButton = document.getElementById('asp_feats');
if (aspButton) {
	aspButton.addEventListener('click', () => {
		document.getElementById('container').classList.add('popup_asp');
	});
}

const advButton = document.getElementById('adv_feats');
if (advButton) {
	advButton.addEventListener('click', () => {
		document.getElementById('container').classList.add('popup_adv');
	});
}

const learnButton = document.querySelectorAll('#learn_modal');
if (learnButton) {
	learnButton.forEach((button) => {
		button.addEventListener('click', () => {
			document.getElementById('container').classList.add('popup_learn');
		});
	})
}

if(window.location.search.includes('?ebook=true')) {
	document.getElementById('container').classList.add('popup_learn');
}


// Get all elements with the class 'popup_close'
const closeButtons = document.querySelectorAll('#popup_close');

// Loop through each close button
if (closeButtons) {
	closeButtons.forEach(closeButton => {
		closeButton.addEventListener('click', () => {
			// Get the closest 'container' element related to the clicked close button
			const container = closeButton.closest('#container');

			// Remove the classes 'popup_vacate' and 'popup_family' from the container
			if (container) {
				container.classList.remove('popup_vacate', 'popup_ads', 'popup_family', 'popup_adv', 'popup_asp', 'popup_learn');
			}
		});
	});
}

// Assuming closeBg has the class 'popup_bg_close'
const closeBgs = document.querySelectorAll('#popup_bg_close');

// Loop through each background close element
if (closeBgs) {
	closeBgs.forEach(closeBg => {
		closeBg.addEventListener('click', () => {
			// Get the closest 'container' element related to the clicked background close element
			const container = closeBg.closest('#container');

			// Remove the classes 'popup_vacate' and 'popup_family' from the container
			if (container) {
				container.classList.remove('popup_vacate', 'popup_family', 'popup_adv', 'popup_asp', 'popup_learn');
			}
		});
	});
}

var subIP = false;
var initializeForm = function(form, requiredFields) {
	form.addEventListener('submit', function(e) {
		e.stopImmediatePropagation();
		e.preventDefault();
		var responseMessage = form.querySelector('.response_message');
		if(form.querySelector('.hp').value != '')return false;
	
		Array.prototype.forEach.call(form.querySelectorAll('.error'), function(error) {
			error.classList.remove('error');
		});
		form.querySelector('.response_message').innerHTML = '';
	
		if(subIP) {
			return;
		}
	
		var errorsFound = new Array();
		requiredFields.forEach(function(item){
			if (item == 'message') {
				const regex = /(http:|https:|www.)/i;
				if (regex.test(form.querySelector('#'+item+'_input').value)) {
					errorsFound.push(item);
				}
			}
			if(form.querySelector('#'+item+'_input').value == ''){
				form.querySelector('#'+item+'_input').classList.add('error');
				errorsFound.push(item);
			}
		});

		if(!subIP && errorsFound.length == 0){
			subIP = true;
			var request = new XMLHttpRequest(),
				method = 'POST',
				url = form.getAttribute('action'),
				data = new FormData(form);
	
			request.onreadystatechange = function() {
				if(request.readyState === 4 && request.status === 200) {
					console.log(request);
					var response = JSON.parse(request.responseText);
					if(response.ret_det.success){
						var input = form.querySelector('.contact_submit');
						input.parentElement.removeChild(input);
						responseMessage.innerHTML = "<span class='success'>"+response.ret_det.message+"</span>";

						const pdfUrl = './static/pdfs/marketing_in_a_cookieless_World.pdf';
						const link = document.createElement('a');
						link.href = pdfUrl;
						link.download = 'Marketing-in-a-cookieless-world.pdf';
						link.style.display = 'none';

						document.body.appendChild(link);

						link.click();

						document.body.removeChild(link);
					}else{
						if(typeof response.data.error_fields !== 'undefined'){
							var errors = [];
							if(response.data.error_fields.length == 1) {
								for(var x = 0; x < response.data.error_fields.length; x++){
									if(response.data.error_fields[x].field_name !== 'recaptcha_token') { 
										errors.push(response.data.error_fields[x].field_name);
									}
								}
								responseMessage.innerHTML = "<span class='error'>"+updateResponseMessage(errors);+"</span>";
							}
						}else{
							responseMessage.innerHTML = "<span class='error'>"+response.ret_det.message+"</span>";
						}
					}
					subIP = false;
				}
			};
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		}
		else if(errorsFound.length > 0){
			responseMessage.innerHTML = "<span class='error'>"+updateResponseMessage(errorsFound);+"</span>";
		}
		else{
			responseMessage.innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
	});
};

function updateResponseMessage(errors){
	var response = '';
	var messagenotice = '';
	if(errors.includes('message')) {
		messagenotice = ' URLs are not allowed in the message.';
	}
	if(errors.length == 1){
		response = "The "+getFriendlyFieldName(errors[0])+" field is required." + messagenotice;
	}else if(errors.length == 2){
		response = "The "+getFriendlyFieldName(errors[0])+" and "+getFriendlyFieldName(errors[1])+" fields are required." + messagenotice;
	}else{
		var fields = "";
		for(var x = 0; x < errors.length; x++){
			fields += getFriendlyFieldName(errors[x]);
			if(x < errors.length - 1){
				fields += ', ';
			}
			if(x == errors.length - 2){
				fields += 'and ';
			}
		}
		response = "The "+fields+" fields are required." + messagenotice;
	}

	return response;
}

var fieldFriendly =  {
	first_name: "first name",
	last_name: "last name",
	employment: "place of employment",
	emergency_name: "emergency contact name",
	emergency_phone: "emergency contact phone",
	military: "military/fire/police/student/teacher",
	lease: "additional person on lease",
	vacate_name: "first and last name",
	vacate_unit: "unit number",
	apply_name: "name",
	apply_address: "address",
	apply_phone: "phone",
	apply_email: "email",
}

const getFriendlyFieldName = function(field) {
	if(typeof fieldFriendly[field] != "undefined") {
		return fieldFriendly[field];
	} else {
		return field;
	}
}

if (document.getElementById('learn_form')) {
	initializeForm(document.getElementById('learn_form'), ['name', 'email', 'company']);
};