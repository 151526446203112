export const homeCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('accordion')) {
			const accordionItems = document.querySelectorAll('#accordion h3');

			accordionItems.forEach(function (item) {
				const button = item.querySelector('#acco_button');
				const context = item.nextElementSibling;

				button.addEventListener('click', function () {
					if (context.style.display === 'none' || context.style.display === '') {
						context.style.display = 'flex';
						button.classList.toggle("clicked");
					} else {
						context.style.display = 'none';
						button.classList.toggle("clicked");
					}
				});
			});
		}
	};

	return my;
})();
