export const webToggle = (function () {
	let my = {};

	my.init = function () {
		if(document.querySelector('#web_styles')) {
			setTimeout(function(){
				document.getElementsByClassName('img_wrap')[0].classList.add('active');
				document.getElementsByClassName('select_ctn')[0].classList.add('active');
				document.getElementsByClassName('style_info')[0].classList.add('active');
				document.getElementsByClassName('toggle')[0].classList.add('active');
				document.getElementsByClassName('lrg_img_wrap')[0].classList.add('active');
				document.querySelectorAll('#custom').forEach(e => e.classList.add('active'));
			}, 100);
		
			document.querySelectorAll('.toggle').forEach( (toggle) => {
				toggle.addEventListener('click', function(e) {
					if(e.target && e.target.nodeName === 'BUTTON'){
						document.querySelectorAll('.toggle').forEach(toggle => toggle.classList.remove('active'));
						e.target.classList.add('active');
		
						if(e.target.id === 'template') {
							document.querySelectorAll('.select_ctn').forEach(e => e.classList.remove('active'));
							document.querySelectorAll('#custom').forEach(e => e.classList.remove('active'));
							document.querySelectorAll('#template').forEach(e => e.classList.add('active'));
							document.querySelectorAll('.style_info').forEach(e => e.classList.remove('active'));
							document.getElementsByClassName('style_info')[3].classList.add('active');
							document.querySelectorAll('.lrg_img_wrap').forEach(e => e.classList.remove('active'));
							document.getElementsByClassName('lrg_img_wrap')[3].classList.add('active');
						} else {
							document.querySelectorAll('.select_ctn').forEach(e => e.classList.remove('active'));
							document.querySelectorAll('#custom').forEach(e => e.classList.add('active'));
							document.querySelectorAll('.style_info').forEach(e => e.classList.remove('active'));
							document.getElementsByClassName('style_info')[0].classList.add('active');
							document.querySelectorAll('.lrg_img_wrap').forEach(e => e.classList.remove('active'));
							document.getElementsByClassName('lrg_img_wrap')[0].classList.add('active');
						}
					}
				});
			});
			
			document.querySelectorAll('.img_wrap').forEach((img) => {
				img.addEventListener('click', function(e) {
					document.querySelectorAll('.img_wrap').forEach((img) => img.classList.remove('active'));
					document.querySelectorAll('.img_wrap')[e.target.getAttribute('data-image')].classList.add('active');
					document.querySelectorAll('.lrg_img_wrap').forEach((img) => img.classList.remove('active'));
					document.querySelectorAll('.lrg_img_wrap')[e.target.getAttribute('data-image')].classList.add('active');
					document.querySelectorAll('.style_info').forEach((img) => img.classList.remove('active'));
					document.querySelectorAll('.style_info')[e.target.getAttribute('data-image')].classList.add('active');
				});
			});
		}
	};

	return my;
})();