export const navBar = (function() {
	let my = {};

	my.init = function() {
		if (document.getElementById('navbar')) {

			document.getElementById("nav_menu").addEventListener("click", function() {
				const img = document.querySelector("#nav_menu img");
				const dropNav = document.getElementById("drop_nav");
				if (img.src.includes("menu_hamburger.svg")) {
					img.src = "/dist/images/icons/SVG/menu_hamburger_exit.svg";
					dropNav.style.display = "flex";
				} else {
					img.src = "/dist/images/icons/SVG/menu_hamburger.svg";
					dropNav.style.display = "none";
				}
			});

			const hasDropElements = document.querySelectorAll('#has_drop');

			hasDropElements.forEach(function(element) {
				element.addEventListener('click', function() {
					const childUl = element.nextElementSibling;
					const img = element.querySelector('img');

					if (childUl.style.display === 'none' || childUl.style.display === '') {
						childUl.style.display = 'block';
						img.style.transform = 'rotate(90deg)';
						img.style.paddingBottom = '10px';
						img.style.paddingLeft = '0';
					} else {
						childUl.style.display = 'none';
						img.style.transform = 'rotate(0deg)';
						img.style.paddingLeft = '10px';
						img.style.paddingBottom = '0';
					}
				});
			});
		}
	};

	return my;
})();
