
export const miniCaro = (function() {
	let my = {};

	my.init = function() {
		if (document.getElementById('carousel_container')) {
			const container = document.querySelectorAll('#carousel_container');

			container.forEach(ctn => {
				const slides = ctn.querySelectorAll('.carousel_slide');
				let currentSlide = 0;
				const leftButton = ctn.parentElement.querySelector('#slide_left');
				const rightButton = ctn.parentElement.querySelector('#slide_right');

				leftButton.addEventListener('click', () => {
					if (currentSlide > 0) {
						currentSlide--;
					} else {
						currentSlide = slides.length - 1;
					}
					updateCarousel();
				});

				rightButton.addEventListener('click', () => {
					if (currentSlide < slides.length - 1) {
						currentSlide++;
					} else {
						currentSlide = 0;
					}
					updateCarousel();
				});

				function updateCarousel() {
					slides.forEach((slide, index) => {
						if (index === currentSlide) {
							slide.classList.add('active');
						} else {
							slide.classList.remove('active');
						}
					});
				}
			});
		}
	};
	return my;
})();
